
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { ComponentsRoutingModule } from "./components.routing.module";
import {HomeVerticalComponent} from './home-vertical/home-vertical.component';
import {HomeKcounterComponent} from './home-kcounter/home-kcounter.component';
// import {HomeStepsComponent} from './home-steps/home-steps.component';
import {HomeDietplanComponent} from './home-dietplan/home-dietplan.component';
import {HomeWaterComponent} from './home-water/home-water.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AddUpdateCustomerComponent } from "./add-update-customer/add-update-customer.component";
import { NavbarComponent } from "../navbar/navbar.component";
import { LeftSideBarComponent } from "../left-side-bar/left-side-bar.component";
import { HeaderComponent } from "../header/header.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    // IonIntlTelInputModule,
    ComponentsRoutingModule,
    NgCircleProgressModule.forRoot({
      animation:false,
      radius:49,
      innerStrokeWidth:6,
      outerStrokeWidth:6,
      space:-6,
      responsive: false,
      showTitle: true,
      titleFontSize:"15",
      subtitleFontSize:"15",
      unitsFontSize:"15",
      renderOnClick:false
    })
    ],
  exports: [
     HomeVerticalComponent,
    // HomeStepsComponent,
    HomeKcounterComponent,
    HomeDietplanComponent,
    HomeWaterComponent,
    AddUpdateCustomerComponent,
    NavbarComponent,
    LeftSideBarComponent,
    HeaderComponent
    
  ],
  declarations: [
    HomeVerticalComponent,
    // HomeStepsComponent,
    HomeKcounterComponent,
    HomeDietplanComponent,
    HomeWaterComponent,
    AddUpdateCustomerComponent,
    NavbarComponent,
    LeftSideBarComponent,
    HeaderComponent
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {}
